<template>
    <div class="animated fadeIn">
		<b-card class="card-border mt-4">
            <b-card-title>Billing Report
                <span class="numFont title">
                    {{ billingReportNo }}
                </span>
            </b-card-title>
            <b-card-sub-title> {{ subTitle }} </b-card-sub-title>
            <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

            <b-form class="mt-4" @submit.stop.prevent="handleSubmit" novalidate>
                <!-- Primary Information -->
                <b-card class="mt-4">
                    <b-row no-gutters>
                        <b-col md="8" sm="6">
                            <b>PRIMARY INFORMATION</b>
                        </b-col>
                    </b-row>

                    <b-row class="mb-2" no-gutters>
                        <!-- Account Details -->
                        <b-col class="mt-4" lg="4" md="4" sm="6">
                            <AccountDetails :billingReport="billingReport" :clientAccount="clientAccount" />
                        </b-col>

                        <!-- Charge Type Details -->
                        <b-col class="mt-4" lg="4" md="4" sm="6">
                            <ChargeTypeDetails :billingReport="billingReport" />
                        </b-col>

                        <!-- Billing Details -->
                        <b-col class="mt-4" lg="4" md="4" sm="6">
                            <BillingDetails :billingReport="billingReport" :hasEdit="true" :hasPreviousBillings="true"
                                @onUpdatePeriod="updateBillingDetails" @onViewPreviousBillings="viewPreviousBillings" />
                        </b-col>
                    </b-row>
                </b-card>

                <!-- Transactions and Adjustments -->
                <b-tabs content-class="mt-4">
                    <b-tab title="Transactions" active>
                        <BillingTransactionsRentalTab :clientAccount="clientAccount"
                            @onUpdateBillingReport="onUpdateBillingReport"
                            v-if="billingReport.chargeType === 'Rental'" />
                        <BillingTransactionsTruckingTab @onUpdateBillingReport="onUpdateBillingReport"
                            v-else-if="billingReport.chargeType === 'Trucking'" />
                        <BillingTransactionsClassificationTab @onUpdateBillingReport="onUpdateBillingReport" v-else
                            :allAssetTypesObj="allAssetTypesObj" />
                    </b-tab>
                    <b-tab title="Adjustments">
                        <BillingAdjustmentsTab :billingReport="billingReport" />
                    </b-tab>
                </b-tabs>

                <!-- Billing Summary -->
                <b-row class="mt-4">
                    <b-col lg="6" offset-lg="6" md="6" offset-md="6" sm="12">
                        <BillingComputationSummary :billingReport="billingReport" />
                    </b-col>
                </b-row>
            </b-form>

            <!-- Navigation Buttons -->
            <b-row class="mt-4">
                <b-col md="12" sm="12" class="my-1 text-sm-right">
                    <b-button variant="secondary" @click="returnToBillingReportMain" class="mr-2">
                        Back
                    </b-button>
                    <b-button v-b-modal.print-billing-report v-b-tooltip.hover.top="'Preview Report'" variant="success"
                        @click="previewReport" :disabled="isLoading" class="mr-2">
                        Preview
                    </b-button>
                    <b-button variant="primary" @click="handleOk" :disabled="isLoading">
                        Save
                    </b-button>
                </b-col>
                <b-col md="1" sm="1" class="my-1"></b-col>
            </b-row>
        </b-card>

        <!-- Modals -->
        <PrintBillingReport :allCompaniesObj="allCompaniesObj" :allUsersObj="allUsersObj"
            :allClientAccountsObj="allClientAccountsObj" />
        <UpdateBillingPeriod />
        <ViewPreviousBillings />
    </div>
</template>

<script>
// Components
import AccountDetails from './primaryDetails/AccountDetails.vue';
import ChargeTypeDetails from './primaryDetails/ChargeTypeDetails.vue';
import BillingDetails from './primaryDetails/BillingDetails.vue';
import BillingAdjustmentsTab from './adjustments/BillingAdjustmentsTab.vue';
import BillingComputationSummary from './BillingComputationSummary.vue';
import BillingTransactionsClassificationTab from './transactions/BillingTransactionsClassificationTab.vue';
import BillingTransactionsRentalTab from './transactions/BillingTransactionsRentalTab.vue';
import BillingTransactionsTruckingTab from './transactions/BillingTransactionsTruckingTab.vue';
import ChargeTypeStatus from './ChargeTypeStatus.vue';
import PrintBillingReport from './PrintBillingReport.vue';
import UpdateBillingPeriod from './UpdateBillingPeriod.vue';
import ViewPreviousBillings from './ViewPreviousBillings.vue';

// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';
import { DateUtil } from '@/utils/dateutil';

// API
import billingReportApi from '@/api/billingReportApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
    name: 'edit-billing-report',
    components: {
        AccountDetails,
        ChargeTypeDetails,
        BillingDetails,
        BillingAdjustmentsTab,
        BillingComputationSummary,
        BillingTransactionsClassificationTab,
        BillingTransactionsRentalTab,
        BillingTransactionsTruckingTab,
        ChargeTypeStatus,
        PrintBillingReport,
        UpdateBillingPeriod,
        ViewPreviousBillings,
        Loading,
    },
    data() {
        return {
            billingReport: {},
            clientAccount: {},

            allCompaniesObj: {},
            allUsersObj: {},
            allAssetTypesObj: {},
            allClientAccountsObj: {},

            // Billing Report Params Field
            params: {},

            isSuperAdmin: this.$store.getters.isSuperAdmin,
            loggedUser: this.$store.getters.loggedUser,
            loggedUserCompany: this.$store.getters.loggedUserCompany,
            // Check for loader
            isLoading: false,
        }
    },
    computed: {
        subTitle() {
            return "Updated last " + this.getFormattedDateWithTime(this.billingReport.dateUpdated);
        },
        billingReportNo() {
            return this.billingReport && this.billingReport.billingReportNo ? this.billingReport.billingReportNo : '-';
        },
        chargeType() {
            let billingReport = this.billingReport ? this.billingReport : {};
            return billingReport.chargeType ? billingReport.chargeType : '';
        },
    },
    mounted() {
        setTimeout(async () => {
            try {
                // Filter Access
				if (this.$store.getters.isScanner || this.$store.getters.isRetailer) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

                // show loading indicator
                this.isLoading = true;

                this.allCompaniesObj = { ...this.$store.getters.companies };
                this.allUsersObj = { ...this.$store.getters.users };
                this.allAssetTypesObj = { ...this.$store.getters.assetTypes };
                this.allClientAccountsObj = { ...this.$store.getters.clientAccounts };

                this.onReset();

            } catch (error) {
                this.$toaster.error('Error loading data. Please reload the page again.');
            }

            // hide loading indicator
            this.isLoading = false;

        }, config.timeout);


        // Event Listeners
        EventBus.$on('onCloseUpdateBillingPeriod', () => {
            // show loading indicator
            this.isLoading = true;

            this.onReset();

            // hide loading indicator
            this.isLoading = false;
        });
    },
    methods: {
        onUpdateBillingReport(billingReport) {
            this.billingReport = billingReport;
            this.$store.commit('SET_CURR_BILLING_REPORT', this.billingReport);
        },
        onReset() {
            // init billingReport
            this.billingReport = { ...this.$store.getters.currBillingReport };

            // init clientAccount
            this.clientAccount = this.allClientAccountsObj[this.billingReport.clientAccountId];

            // init billing report params
            this.params = this.$store.getters.billingReportParams;

            if (this.chargeType === 'Rental') {
                EventBus.$emit("onUpdateTransactionsRental", this.billingReport);
            } else if (this.chargeType === 'Trucking') {
                EventBus.$emit("onUpdateTransactionsTrucking", this.billingReport);
            } else {
                EventBus.$emit("onUpdateTransactionsClassification", this.billingReport);
            }

            EventBus.$emit("onUpdateBillingAdjustments", this.billingReport);
        },

        returnToBillingReportMain() {
            this.params.fromEditBillingReport = true;

            this.$store.dispatch('setBillingReportParams', this.params);
            this.$store.commit('SET_CURR_BILLING_REPORT', {});

            if (this.isSuperAdmin) {
                this.$router.push({ path: '/admin/admin-billing-report' });
            } else {
                this.$router.push({ path: '/billing-report' });
            }
        },
        previewReport() {
            EventBus.$emit('onPrintBillingReport', this.billingReport);
        },

        hasInvalidTruckingCost(reportObj) {
            let hasInvalid = false;
            let transactions = reportObj.transactions;

            for (let transaction of transactions) {
                if (transaction.billable) {
                    let VATExRate = parseFloat(transaction.vatExclusiveRate);
                    if (_.isNaN(VATExRate)) {
                        hasInvalid = true;
                        break;
                    } else if (VATExRate === 0) {
                        hasInvalid = true;
                        break;
                    } else if (VATExRate > 100000) {
                        hasInvalid = true;
                        break;
                    }
                }
            }
            return hasInvalid;
        },
        async handleOk(evt) {
            // Prevent modal from closing
            evt.preventDefault();
            // show loading indicator
            this.isLoading = true;

            let isValid = await this.$validator.validateAll();
            if (!isValid) {
                this.$toaster.warning('Please address the field/s with invalid input.');
                this.isLoading = false;
                return;
            }

            let reportObj = BillingReportUtil.cleanupFields(this.billingReport);
            if (reportObj.totalVATExclusiveAmount < 0) {
                this.$toaster.warning('Invalid adjustment values. Please check to proceed saving.');
                // hide loading indicator
                this.isLoading = false;
                return;
            }

            if (reportObj.chargeType === 'Trucking' && this.hasInvalidTruckingCost(reportObj)) {
                this.$toaster.warning('Invalid Trucking Cost. Please check the VAT Exclusive Rate for all dispatches with billable trucking.');
                // hide loading indicator
                this.isLoading = false;
                return;
            }

            await this.handleSubmit(reportObj);
        },
        async handleSubmit(reportObj) {
            try {
                let { data } = await billingReportApi.saveBillingReport(
                    reportObj,
                    this.loggedUser.id,
                    DateUtil.getCurrentTimestamp()
                );

                if (data.isSuccess) {
                    this.$toaster.success(`Billing Report "${reportObj.billingReportNo}" was updated successfully.`);

                    // Redirect to Billing Report Main
                    this.returnToBillingReportMain();
                } else {
                    this.$toaster.error(`Error updating billing report "${reportObj.billingReportNo}". Please try again.`);
                }

            } catch (error) {
                this.$toaster.error(`Error updating billing report "${reportObj.billingReportNo}". Please try again.`);
            }

            // hide loading indicator
            this.isLoading = false;
        },

        updateBillingDetails() {
            this.$store.commit('SET_CURR_BILLING_REPORT', this.billingReport);

            let param = {
                billingReport: this.billingReport,
                clientAccount: this.clientAccount,
                hasPreviousBilling: this.hasPreviousBilling
            };
            EventBus.$emit("onUpdateBillingPeriod", param)
        },
        viewPreviousBillings() {
            this.$bvModal.show('view-previous-billings');
            EventBus.$emit('onViewPreviousBillings', this.billingReport);
        },

        // UTILS
        getFormattedDateWithTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
        getFormattedDate(date) {
            return DateUtil.getFormattedDate(date);
        }
    },
    beforeDestroy() {
        EventBus.$off('onCloseUpdateBillingPeriod');
    },
}
</script>

<style scoped>
.title {
    font-size: 22px !important;
}

.field-label {
    font-weight: bold;
    margin-right: 10px;
    margin-top: 3px;
}

.field-value {
    font-size: 16px !important;
}
</style>